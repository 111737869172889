<template>
	<q-layout id="main-layout" view="HHH Lpr FFF">
		<q-header elevated>
			<q-toolbar class="text-primary bg-white text-weight-bold">
				<router-link to="/">
					<q-img
						id="product-name"
						no-spinner
						no-transition
						alt="docxworld.SPHERE logo"
						src="sphere-logo.png"
						style="height: 52px; width: 240px"
					/>
				</router-link>
				<q-space />
				<q-btn
					size="lg"
					flat
					no-caps
					label="Datenschutz"
					href="https://www.profiforms.de/datenschutz"
					target="_blank"
				/>
				<q-btn
					size="lg"
					flat
					no-caps
					label="Kontakt"
					href="https://www.profiforms.de/allgemeines-kontaktformular-profiforms"
					target="_blank"
				/>
				<q-btn id="impressum-button" size="lg" flat no-caps label="Impressum" :to="'/impressum'" />
			</q-toolbar>
			<q-toolbar class="bg-primary" style="height: 70px">
				<q-btn
					id="drawer-toggle-button"
					flat
					dense
					round
					@click="drawerVisible = !drawerVisible"
					aria-label="Menu"
					icon="menu"
				/>
				<q-toolbar-title class="text-h5 text-negative"> {{ toolbarTitle }} </q-toolbar-title>
				<q-btn v-if="loggedIn" class="toolbar-button q-ma-md" no-caps id="logout-button" @click="logout()"
					>Abmelden</q-btn
				>
			</q-toolbar>
		</q-header>
		<q-drawer id="the-drawer" v-model="drawerVisible" show-if-above class="bg-primary text-white">
			<q-list>
				<q-item id="overview-link" :to="'/overview'" active-class="active-link">
					<q-item-section avatar>
						<q-icon name="list" />
					</q-item-section>
					<q-item-section>
						<q-item-label>Übersicht</q-item-label>
					</q-item-section>
				</q-item>
				<q-item id="e-invoce-link" :to="'/e-invoice'" active-class="active-link">
					<q-item-section avatar>
						<q-icon name="list" />
					</q-item-section>
					<q-item-section>
						<q-item-label>E-Rechnung</q-item-label>
					</q-item-section>
				</q-item>
				<!-- Barrierefreiheit kommt zu einem späteren Zeitpunkt -->
				<!-- <q-item id="accessibility-link" :to="'/accessibility'" active-class="active-link">
					<q-item-section avatar>
						<q-icon name="list" />
					</q-item-section>
					<q-item-section>
						<q-item-label>Barrierefreies PDF</q-item-label>
					</q-item-section>
				</q-item> -->
				<q-item id="api-docs-link" :to="'/api-docs'" active-class="active-link">
					<q-item-section avatar>
						<q-icon name="api" />
					</q-item-section>
					<q-item-section>
						<q-item-label>API Dokumentation</q-item-label>
					</q-item-section>
				</q-item>
			</q-list>
		</q-drawer>
		<q-page-container>
			<q-page>
				<router-view @good-key-given="login" />
			</q-page>
		</q-page-container>
		<q-footer>
			<q-toolbar>
				<q-toolbar-title />
				<div class="text-h5 q-mx-md">powered by</div>
				<q-img
					no-spinner
					no-transition
					alt="profiforms logo"
					src="profifroms-logo-negativ-menubar.svg"
					style="height: 52px; width: 240px"
				/>
			</q-toolbar>
		</q-footer>
	</q-layout>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const loggedIn = ref(localStorage.getItem('ApiKey'));
const drawerVisible = ref(false);
const route = useRoute();
const router = useRouter();
const toolbarTitle = computed(() => route.meta.title);

function login(apiKey) {
	localStorage.setItem('ApiKey', apiKey);
	loggedIn.value = true;
	router.go();
}

function logout() {
	localStorage.removeItem('ApiKey');
	loggedIn.value = false;
	router.go();
}
</script>


<style scoped>
.custom-markdown .q-markdown--heading-h3 {
	font-size: 1.2rem;
	line-height: 1.2rem;
}
</style>