import "./styles/quasar.sass";
import lang from "quasar/lang/de-DE.js";
import "@quasar/extras/material-icons/material-icons.css";
import Notify from 'quasar/src/plugins/notify/Notify.js';;

// To be used on app.use(Quasar, { ... })
export default {
	plugins: {
		Notify,
	},
	config: {},
	lang: lang,
};
