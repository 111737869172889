import Quasar from 'quasar/src/vue-plugin.js';;
import { createApp } from 'vue';
import App from './App.vue';
import quasarUserOptions from './quasar-user-options';
import router from './router.js';

import qmarkdown from '@quasar/quasar-ui-qmarkdown/src/QMarkdown.js';
import '@quasar/quasar-ui-qmarkdown/src/QMarkdown.sass';

createApp(App, { windowLocationSearch: window.location.search })
	.use(Quasar, quasarUserOptions)
	.use(qmarkdown)
	.use(router)
	.mount('#app');
