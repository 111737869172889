import { createRouter, createWebHashHistory } from 'vue-router';

const protectedComponent = (authorizedImport) => () => {
	return localStorage.getItem('ApiKey') ? authorizedImport() : import('./pages/LoginPage.vue');
};

const routes = [
	{
		path: '/',
		redirect: '/overview',
	},
	{
		path: '/overview',
		component: protectedComponent(() => import('./pages/Overview.vue')),
		meta: { title: 'Übersicht' },
	},
	{
		path: '/e-invoice',
		component: protectedComponent(() => import('./pages/E-Invoice.vue')),
		meta: { title: 'E-Rechnung' },
	},
	{
		path: '/impressum',
		component: () => import('./pages/Impressum.vue'),
		meta: { title: 'Impressum' },
	},
	{
		path: '/api-docs',
		component: protectedComponent(() => import('./pages/ApiDocs.vue')),
		meta: { title: 'API Dokumentation' },
	},
];

const router = createRouter({
	scrollBehavior: () => ({ left: 0, top: 0 }),
	routes,
	history: createWebHashHistory(),
});

export default router;
